<template>
    <div class="notifications">
        <Notification
            v-for="alert in this.$root.notifications"
            v-bind:key="alert.id+alert.message"
            v-bind:message="alert.message"
            v-bind:heading="alert.heading"
            v-bind:type="alert.type"
            v-bind:id="alert.id"
            :dissmiss="()=>removeIt(alert.id)"
        >
        </Notification>
    </div>
</template>

<script>
    import Notification from "./Notification";

    export default {
        name: "Notifications",
        components: {Notification},
        // props: {
        //     // timeout: {default: 5000}
        // },
        // data: function () {
        //     return {
        //         // maxId: 0,
        //         // notifications: []//[{type: "danger", message: "ouch..."}]
        //         //notifications: [{type: "danger", message: " Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Vestibulum fermentum tortor id mi. Duis risus. Vestibulum fermentum tortor id mi. Fusce tellus odio, dapibus id fermentum quis, suscipit id erat. Duis condimentum augue id magna semper rutrum. Nullam sit amet magna in magna gravida vehicula. Praesent dapibus. Fusce suscipit libero eget elit.\n" +
        //         //        "\n" +
        //         //        "Sed convallis magna eu sem. Nullam at arcu a est sollicitudin euismod. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. In enim a arcu imperdiet malesuada. Duis risus. Cras elementum. Etiam dictum tincidunt diam. In convallis. Itaque earum rerum hic tenetur a sapiente delectus, ut aut reiciendis voluptatibus maiores alias consequatur aut perferendis doloribus asperiores repellat. Praesent in mauris eu tortor porttitor accumsan. Curabitur ligula sapien, pulvinar a vestibulum quis, facilisis vel sapien. Etiam dui sem, fermentum vitae, sagittis id, malesuada in, quam. Proin pede metus, vulputate nec, fermentum fringilla, vehicula vitae, justo. Praesent in mauris eu tortor porttitor accumsan. Quisque tincidunt scelerisque libero. Vestibulum erat nulla, ullamcorper nec, rutrum non, nonummy ac, erat. Quisque porta. Aliquam ornare wisi eu metus. Praesent vitae arcu tempor neque lacinia pretium. Aliquam erat volutpat. ..."}]
        //     }
        // },
        methods: {
            addNotification(type, message,heading = undefined) {
                this.$root.addNotification(type,message, heading);
            },
          removeIt(id){
            this.$root.handleTimeout(id);
          }
        }
    }
</script>

<style scoped>
    .notifications {
        position: absolute;
        top: 4.5rem;
        right: 1.5rem;
        margin-left:1.5rem;
        max-width: 30rem;
        z-index: 10000;
    }
</style>