import Vue from 'vue'
import App from './App.vue'
import updated from './registerServiceWorker'
import router from './router'
import store from './store'
import VueSocketIO from 'vue-socket.io'
import {fix} from "./browserFixes.js"
import { ModalPlugin,VBTooltipPlugin,SidebarPlugin,ButtonPlugin,TabsPlugin,CardPlugin   } from 'bootstrap-vue'

import {version,buildDate} from './../package.json';
console.info("version: ",version,"build date: ",buildDate);
Vue.use(SidebarPlugin)
Vue.use(VBTooltipPlugin)
Vue.use(ModalPlugin)
Vue.use(ButtonPlugin)
Vue.use(TabsPlugin)
Vue.use(CardPlugin)
Vue.config.productionTip = false

const strategies = Vue.config.optionMergeStrategies;
strategies.sockets = strategies.methods;

fix();
const host = window.location.host;
const socket_connection = host.includes('localhost') ?
    'http://localhost:3000' : (host.includes('192.168.0.23') ?
        'http://192.168.0.23:3000' : (host.includes('192.168.1.23') ?
            'http://192.168.1.23:3000' : (host.includes('192.168.0.230') ?
                'http://192.168.0.230:3000' : (host.includes('192.168.0.224') ?
                    'http://192.168.0.224:3000' : host))));
// socket_connection = ;
Vue.use(new VueSocketIO({
  debug: true,
  connection: socket_connection,
  vuex: {
    store,
    actionPrefix: 'SOCKET_',
    mutationPrefix: 'SOCKET_'
  },
  options: { path: "/socket.io/",timeout: 60000 } //Optional options
}))

new Vue({
  router,
  store,
  data:function(){
    let name="";
    if (window.localStorage && window.localStorage['name']) {
      name = window.localStorage['name'];
    }

    if(updated.value){
      this.addNotification('info','Nová verze k dispozici, prosím znovu načti stránku (CTRL+R).',0,"Aktualizace") ;
    }
    return{
      notifications:[],
      timeout: 5000,
      maxId:0,
      maxNotifications:5,
      playerName:name,
    }
  },
  methods: {
    addNotification(type, message, length, heading = undefined) {
      console.log(type, message,heading,length);
      this.maxId += 1;
      this.notifications.unshift({id: 0+this.maxId, type: type, message: message, heading: heading});
      if(this.notifications.length>this.maxNotifications){
        this.notifications.splice(this.maxNotifications, this.notifications.length);
      }
      let id = 0+this.maxId;
      // let tis = this;
      if(length){
        setTimeout(()=>this.handleTimeout(id), length);
      }
    },
    handleTimeout:function (id) {
      let newNotif = this.notifications.filter(function (value) {
        // console.log("Removing " + id, value.id);
        return value.id !== id;
      });
      this.notifications = newNotif;
    },
    changeName:function(newName){
      this.playerName = newName;
    }
  },
  watch:{
    // updated:function () {
    //   this.addNotification('info','Nová verze k dispozici, prosím znovu načti stránku.',0,"Aktualizace") ;
    // }
  },
  // components:{App},
  // template: '<app :playerName="playerName"></app>',
  render: function (h) {
    return h(App, {playerName:this.playerName})
  }
}).$mount('#cod')
