<template>
  <div class="container-fluid">
    <game-list :playerName="playerName" :autoConnect="autoConnect"></game-list>
  </div>
<!--  <div class="row">-->
<!--    <div class="col">-->

<!--    </div>-->
<!--  </div>-->

</template>

<script>
// @ is an alias to /src
// import Chat from '@/components/Chat/Chat.vue'
import GameList from "@/components/CircleOfDeath/GameList";

export default {
  name: 'Home',
  props: ['playerName','autoConnect'],
  components: {
    GameList,
  }
}
</script>
<style scoped>

</style>
