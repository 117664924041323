<template>
  <div class="container-fluid" style="min-height: 100%;">

    <div class="row justify-content-center" v-if="!connectedRoom">
      <div class="col col-sm-6">
        <h1>Seznam herních místností</h1>
      </div>
    </div>
    <div class="row justify-content-center" v-if="connectedRoom && initRoomData">
      <div class="col">
        <game
            :room="connectedRoom"
            :initRoomData="initRoomData"
            :key="(initRoomData&&initRoomData.name) || 't'"
            :initCards="recievedCards"
            :playerName="this.playerName"
        />
      </div>
    </div>

    <div class="row justify-content-center" v-else-if="availableRooms.length>0">
      <div class="col col-sm-6">
        <!--        <games-->
        <!--               :available-rooms="availableRooms"-->
        <!--               :connect-to-room="connectToRoom"-->
        <!--        />-->

        <list-rooms v-if="playerName!==''"
                    :available-rooms="availableRooms"
                    :player-name="this.playerName"
                    @connectToRoom="connectToRoom"
                    @deleteRoom="deleteRoom">
          <template v-slot:default="slotProps">
            {{ slotProps.room.name }} ({{ slotProps.room.shownCards.length }}/{{ slotProps.room.cards.length }})
          </template>
        </list-rooms>
        <!--        <ul class="list-group" v-if="playerName!==''">-->
        <!--          <li v-for="room in availableRooms" :key="room.name" class="list-group-item">-->
        <!--            <span class="btn btn-link" @click="connectToRoom(room.name,playerName)">{{ room.name }} ({{room.shownCards.length}}/{{room.cards.length}})</span>-->
        <!--            <span class="btn btn-link" v-if="room.creator===playerName" @click="deleteRoom(room.name)">×</span>-->
        <!--            <table class="table table-sm"  v-if="room.players.length>0">-->
        <!--              <tbody>-->
        <!--                <tr v-for="player in room.players" :key="player.name" class="ml-2">-->
        <!--                  <td>{{ player.name }}</td>-->
        <!--                </tr>-->
        <!--              </tbody>-->
        <!--            </table>-->
        <!--            <table class="table table-sm" v-else >-->
        <!--              <tbody>-->
        <!--              <tr class="ml-2">-->
        <!--                <td>No players</td>-->
        <!--              </tr>-->
        <!--              </tbody>-->
        <!--            </table>-->
        <!--&lt;!&ndash;            <ul v-if="room.players.length>0" class="list-group">&ndash;&gt;-->
        <!--&lt;!&ndash;              <li v-for="player in room.players" :key="player.name" class="list-group-item ml-2">{{ player.name }}</li>&ndash;&gt;-->
        <!--&lt;!&ndash;            </ul>&ndash;&gt;-->
        <!--            &lt;!&ndash;        <ul v-if="room.players.length>0"  class="list-group">&ndash;&gt;-->
        <!--&lt;!&ndash;            <ul v-else class="list-group">&ndash;&gt;-->
        <!--&lt;!&ndash;              <li class="list-group-item  ml-2">No players</li>&ndash;&gt;-->
        <!--&lt;!&ndash;            </ul>&ndash;&gt;-->
        <!--          </li>-->
        <!--        </ul>-->
        <p class="" v-else>
          Nejprve si zvol jméno, pak můžes začít hrát.
        </p>
      </div>
    </div>

    <div class="row justify-content-center mt-2" v-if="playerName!==''">
      <div class="col">
        <create-room v-if="!connectedRoom"
                     :currentRooms="getRoomNames()"
                     :player-name="playerName"
                     @createRoom="createRoom"
        />
      </div>
    </div>

  </div>
</template>

<script>
// import Game from "@/components/CircleOfDeath/Game/Game";
// import CreateRoom from "@/components/CircleOfDeath/Game/CreateRoom";
// import Games from "@/components/GameList/Games";
// import Row from "@/components/Helpers/Row";
import BCol from "@/components/Helpers/Col";
// import roomsMixin from "@/mixins/rooms";

// import ListRooms from "@/components/Helpers/ListRooms";
// import CreateRoom from "@/components/Helpers/CreateRoom";

export default {
  name: "GameList",
  // mixins: [roomsMixin],
  components: {
    // Row,
    // Games,
    'Game': () => import('@/components/CircleOfDeath/Game/Game'),
    BCol,
    'ListRooms': () => import(/* webpackChunkName: "list_rooms" */ '@/components/Helpers/ListRooms'),
    'CreateRoom': () => import(/* webpackChunkName: "create_room" */ '@/components/Helpers/CreateRoom')
  },
  props: ['playerName', 'autoConnect'],
  data: function () {
    // console.log('autoConnect', this.autoConnect, 'path', this.$route.path)
    let connectedRoom = false
    // if(this.$route.path !== '/game' && (this.$route.query.g!==''&&this.$route.query.g!==undefined)){
    if (this.$route.path === '/game' &&
        this.autoConnect &&
        this.playerName.toString().length > 0 &&
        Object.prototype.hasOwnProperty.call(this.$route.query, 'g')) {
      console.log('should autoconect', this.$route.query.g)
      connectedRoom = this.$route.query.g;
      this.connectToRoom(this.$route.query.g)
    }
    return {
      availableRooms: [],
      connectedRoom,
      recievedCards: [],
      initRoomData: null
    }
  },
  beforeMount() {
    setTimeout(this.askForRooms, 250);
  },
  sockets: {
    getRooms: function (data) {
      //console.log(data)
      this.availableRooms = data;
    },
    // disconnect: function () {
    //   console.log("disconecting...");
    //   this.disconnectRoom();
    // },
    // kickPlayer:function(){
    //   console.log("You've been kicked :D .. ");
    //   this.$root.addNotification('danger',
    //       'Vykopnutí není určite bez důvodu ;)',
    //       0, 'Soow Sowwy...');
    //   this.disconnectRoom();
    // },
    // changeName: function () {
    //   this.connectedRoom = false;
    //   this.$root.addNotification('danger', 'Nelze se připojit v místosti je již hráč se stejným jménem.');
    //   this.askForRooms();
    // },
    connectToRoom: function (data) {
      this.initRoomData = data.room;
      try {
        if (this.$route.path !== '/game' && this.$route.query.g !== this.initRoomData.name) {
          // this.$router.push({
          this.$router.push({
            path: "/game",//;/"+this.initRoomData.name,
            // path:"?game="+this.initRoomData.name,
            query: {g: this.initRoomData.name}

          });
        }
      } catch (e) {
        //nasrat ;)
      }

    }
  },
  methods: {
    connectToRoom: function (room) {
      let response = this.$socket.emit('connectToRoom',
          {room, player: (this.playerName || this.$attrs.playerName || window.localStorage['name'])}
      );
      //console.log(response);
      this.connectedRoom = room;
      // this.initRoomData = room;
    },
    deleteRoom: function (name) {
      this.$socket.emit('deleteRoom', {name, player: this.playerName});
    },
    createRoom: function (params) {
      this.$socket.emit('createRoom', {name: params.name, player: params.player});
    },
    disconnectRoom: function () {
      if (this.connectedRoom) {
        this.$socket.emit('disconnectRoom',
            {room: this.connectedRoom, player: (this.$attrs.playerName || window.localStorage['name'])}
        );
        // console.log("unsubsribing");
        this.connectedRoom = false;
      }
    },
    askForRooms: function () {
      // console.log("asking for rooms");
      this.$socket.emit('getRooms');
    },
    getRoomNames: function () {
      return this.availableRooms.map((r) => r.name) || [];
    },
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      console.log('Route change', from, to);
      // if(from.path==='/game'&& to.path ==='/'){
      if (Object.prototype.hasOwnProperty.call(from.query, 'g') &&
          !Object.prototype.hasOwnProperty.call(to.query, 'g')) {
        console.log('Route change', 'matched');
        this.disconnectRoom();
        // await this.$nextTick();
        this.connectedRoom = false;
      }
    }
  }
}
</script>

<style scoped>

</style>