<template>
        <div :class="['alert','alert-'+this.type]" role="alert">
            <button type="button"
                    class="close" data-dismiss="alert" aria-label="Close"
            @click="dissmiss">
                <span aria-hidden="true">&times;</span>
            </button>
          <h4 class="alert-heading" v-if="heading">{{heading}}</h4>
          <hr  v-if="heading">
          <p>
            {{message}}
          </p>

        </div>
</template>

<script>
    export default {
        name: "Notification",
        props:['id','type','message','heading','dissmiss','toast'],

    }
</script>

<style scoped>
</style>