import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
// import Game from '../views/Home.vue'
// import Options from '../views/Options.vue'
// import Nuts from '../views/Nuts.vue'
// import Machacek from "../views/Machacek";
// import MauMau from "../views/MauMau";
// import NotFound from "../views/404";

Vue.use(VueRouter)


// function dynamicPropsFn (route) {
//   // const datas ={...data}
//   return   {
//     ...route.query
//   }
//
// }

const routes = [
  {
    meta:{
      title:'Circle Of Death'
    },
    path: '/',
    name: 'Home',
    component: Home
    // component: () => import(/* webpackChunkName: "cod" */ '../views/Home.vue'),
  },
  {
    meta: {
      showModal: true,
      autoConnect:true,
      title:'Circle Of Death'
    },
    path:'/game',
    name:'Game',
    component: Home,
    // component: () => import(/* webpackChunkName: "cod" */ '../views/Home.vue'),
    props: {autoConnect:true}
  },
  {
    meta: {
      showModal: true,
      autoConnect:true,
      title:'Circle Of Death'
    },
    path:'/machacek',
    name:'Machacek',
    // component: Machacek,
    component: () => import(/* webpackChunkName: "machacek" */ '../views/Machacek.vue'),
    props: {autoConnect:true}
    // props: dynamicPropsFn
  },
  {
    meta: {
      showModal: true,
      autoConnect:true,
      title:'Prší'
    },
    path:'/prsi',
    name:'MauMau',
    // component: MauMau,
    component: () => import(/* webpackChunkName: "maumau" */ '../views/MauMau.vue'),
    props: {autoConnect:true}
    // props: dynamicPropsFn
  },
  {
    meta: {
      showModal: true,
      autoConnect:true,
      title:'Kamen Nůžky Papír'
    },
    path:'/rps',
    name:'RockPaperScizor',
    // component: MauMau,
    component: () => import(/* webpackChunkName: "rps" */ '../views/RockPaperScizor.vue'),
    props: {autoConnect:true}
    // props: dynamicPropsFn
  },
  // {
  //   path:'/nuts',
  //   name:'Nuts',
  //   component: Nuts,
  //   // props: dynamicPropsFn
  // },
  {
    meta:{
      title:'O Aplikaci'
    },
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    meta:{
      title:'Nastavení'
    },
    path:'/options',
    name:'Options',
    // component: Options
    component: () => import(/* webpackChunkName: "options" */ '../views/Options.vue')
  },
  {
    meta:{
      title:'Poznámky k vydání'
    },
    path:'/release-notes',
    name:'ReleaseNotes',
    // component: Options
    component: () => import(/* webpackChunkName: "release_notes" */ '../views/ReleaseNotes.vue')
  },
  {
    meta:{
      title:'Stránka nenalezena'
    },
    path: "/*",
    // component: NotFound
    component: () => import(/* webpackChunkName: "not_found" */ '../views/404.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
