<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="input-group mb-3" v-if="!hideAble || !nameDone">
        <input id="name"
               type="text"
               :class="['form-control', this.errName && 'is-invalid', this.nameDone && 'is-valid']"
               v-model="name"
               :disabled="disableNameChange"
               placeholder="Jméno" aria-label="Jméno" aria-describedby="basic-addon2" />
        <div class="input-group-append">
          <button class="btn btn-dark "
                  type="button" @click="setName"
                  :disabled="disableNameChange">
            Nastavit jméno
          </button>
        </div>
      </div>
      <div v-else></div>
    </div>
  </div>

</template>

<script>
export default {
  name: "ChangeName",
  props:{
    hideAble:{
      default:true
    },
    disableNameChange:{
      default: false
    }
  },
  data: function () {
    let defName = "";
    if (window.localStorage) {
      defName = window.localStorage['name'] || ""
    }
    return {
      name: defName,
      errName: false,
      nameDone: false
    }
  },
  methods: {
    setName: function () {
      if(this.errName)
        return
      if (window.localStorage) {
        window.localStorage['name'] = this.name;
      }
      this.nameDone=true;
      this.$root.changeName(this.name);
      // this.$root.playerName = this.name;
      // this.$root.$data.playerName = this.name;
      // this.name = chatName;
    },
  },
  watch: {
    name: function (name) {
      if (name != "") {
        this.errName = false;
      } else {
        this.errName = true;
      }
      // this.setName(name);
    }

  }

}
</script>

<style scoped>

</style>