<template>
<div :class="['col',...this.class]">
  <slot></slot>
</div>
</template>

<script>
export default {
  name: "BCol",
  beforeCreate() {
    //console.log(this.$attrs)
  }
}
</script>

<style scoped>

</style>