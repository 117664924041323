<template>
  <article id="app">
    <header role="banner">
      <nav id="nav">
        <router-link to="/">Hrát Circle</router-link>
        |
        <!--        <router-link to="/machacek">Hrát Macháčka</router-link>-->
        <!--        |-->
        <!--        <router-link to="/nuts">Hrát Oříšky</router-link>-->
        <!--        |-->
<!--        <router-link to="/prsi">Hrát Prší</router-link>-->
<!--        |-->

        <router-link to="/rps">Hrát RPS</router-link>
        |

        <router-link v-if="!showModalMeta" to="/options">Nastavení</router-link>
        <a href="#" v-else @click="showModalOptions=!showModalOptions">Nastavení</a>
        |
        <router-link v-if="!showModalMeta" to="/about">O Hře</router-link>
        <a href="#" v-else @click="showModalAbout=!showModalAbout">O Hře</a>
        |

        <a href="#" class="no-underline" @click="changeTheme()" v-b-tooltip.hover="'Změna light/dark módu'">{{ schemaText[scheme] }}</a>
<!--        <a href="#" @click="changeTheme(true)"> default </a>-->
      </nav>
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-6">
            <change-name v-if="name===''"/>
          </div>
        </div>
      </div>
      <notifications/>
    </header>
    <main class="flex-fill" style="margin-top:2em">
      <router-view v-bind="myProps()"/>
    </main>
    <div v-if="showModalMeta&&(showModalOptions||showModalAbout)" class="mymodal-route">
      <div class="mymodal-content">
        <button class="btn btn-outline-dark mymodal-close p-0 m-1" style="" @click="closeModal">×</button>
        <!--        <router-view></router-view>-->
        <options v-if="showModalOptions" disableNameChange/>
        <about v-if="showModalAbout" noquotes="true"/>
      </div>
    </div>
  </article>
</template>

<script>

import Notifications from "@/components/Notification/Notifications";
import ChangeName from "@/components/Options/ChangeName";
import {BSidebar} from 'bootstrap-vue'
// import Options from "@/views/Options";
// import About from "@/views/About";

export default {
  components: {
    'About': () => import(/* webpackChunkName: "about" */ '@/views/About'),
    'Options':() => import(/* webpackChunkName: "options" */ '@/views/Options'),
    Notifications, ChangeName, BSidebar
  },
  props: ['playerName'],
  data: function () {
    let scheme;
    let schemaText = {
      dark: "🌔",// 💡 🌞
      light: "🌒", // 🌓 🌑
      // auto: "?", // 🌓 🌑
    }
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode;
      scheme = 'dark';
    } else {
      scheme = 'light';
    }
    return {
      color: '',
      scheme,
      schemaText,
      showModalMeta: false,
      showModalOptions: false,
      showModalAbout: false,
      name: this.$root.playerName,
      errName: false,
      nameDone: false
    }
  },
  beforeMount() {
    this.$root.app = this;
    this.sidebar();
  },
  methods: {

    myProps: function () {
      return {playerName: this.$root.playerName, nameDone: this.nameDone};
    },
    sidebar: function () {
      this.$root.sidebar = () => this.$refs.sidebar
    },
    closeModal: function () {
      this.showModalAbout = false;
      this.showModalOptions = false;
    },
    changeTheme: function (auto = false) {
      // if (auto) {
      //   this.setPreferedTheme('auto');
      //   return;
      // }
      // let currSchema = this.scheme;
      let nextSchema = this.scheme == 'dark' ? 'light' : 'dark';
      this.setPreferedTheme(this.scheme);
      this.scheme = nextSchema;
    },
    setPreferedTheme: function (mode) {
      for (var s = document.styleSheets.length - 1; s >= 0; s--) {
        for (var i = document.styleSheets[s].rules.length - 1; i >= 0; i--) {
          let rule = document.styleSheets[s].rules[i].media;
          if (rule?.mediaText.includes("prefers-color-scheme")) {
            switch (mode) {
              case "light":
                rule.appendMedium("original-prefers-color-scheme");
                if (rule.mediaText.includes("light")) rule.deleteMedium("(prefers-color-scheme: light)");
                if (rule.mediaText.includes("dark")) rule.deleteMedium("(prefers-color-scheme: dark)");
                break;
              case "dark":
                rule.appendMedium("(prefers-color-scheme: light)");
                rule.appendMedium("(prefers-color-scheme: dark)");
                if (rule.mediaText.includes("original")) rule.deleteMedium("original-prefers-color-scheme");
                break;
              default:
                rule.appendMedium("(prefers-color-scheme: dark)");
                if (rule.mediaText.includes("light")) rule.deleteMedium("(prefers-color-scheme: light)");
                if (rule.mediaText.includes("original")) rule.deleteMedium("original-prefers-color-scheme");
            }
            break;
          }
        }
      }
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: function (newVal, oldVal) {
        console.log(newVal);
        this.showModalMeta =  newVal.query.g && newVal.meta && newVal.meta.showModal;
        if(newVal.meta&&newVal.meta.title){
          console.log(newVal.meta.title);
          document.title = newVal.meta.title;
        }
      }
    }
  },
  computed: {
    options: function () {
      if (this.showModal) {
        console.log('test')
        return "game/options"
      }
      return "/options"
    }
  }

}
</script>

<style>
@media (prefers-color-scheme: dark) {
  :root {
    --background-color: #181818;
    --text-color: #c1c1c1;
    --header-color: #77a5de;
    --active-link-color: #42b983;
    --link-color: #c1c1c1;
    --rooms-color: lightgray;
    --rooms-line-color: #969699;
    --content-modal-bg-color: #181818; /*lightgray;*/
    --content-modal-shadow-color: #77a5de;
    /*--content-modal-close-color: red;*/
    --content-modal-close-color: #bc2323;
    --content-modal-close-hover-color: #bc2323;
    --card-color: #2c3e50;
  }
}

@media (prefers-color-scheme: light) {
  :root {
    --background-color: lightgray;
    --text-color: #2c3e50;
    --header-color: #2c3e50;
    --active-link-color: #42b983;
    --link-color: #2c3e50;
    --rooms-color: white;
    --rooms-line-color: #dee2e6;
    --content-modal-bg-color: white;
    --content-modal-shadow-color: #181818;
    --content-modal-close-color: transparent;
    --content-modal-close-hover-color: #181818;
    --card-color: #2c3e50;
  }
}

html {
  /*overflow: hidden; !* Hide scrollbars *!*/
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none;
}

body {
  /*background-color: lightgray;*/
  background-color: var(--background-color);
}

html, body {
  width: 100%;
  height: 100%;
}

article {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

main {
  flex-grow: 1;
}

.footer {
  height: 24px; /* Set the fixed height of the footer here */
  line-height: 24px; /* Vertically center the text there */
}

header, main, footer {
  flex-shrink: 0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--text-color);
  /*scrollbar-track-color: var(--content-modal-bg-color);*/
}

#nav {
  padding: 20px;
}

#nav a {
  font-weight: bold;
  color: var(--link-color);
}

#nav a.router-link-exact-active {
  color: var(--active-link-color);
}
.no-underline{
  text-decoration-line: none;
  outline:none;
}
.no-underline:hover{
  text-decoration-line: none;
  outline:none;
}
.mymodal-route {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /*background: rgba($color: #000000, $alpha: 0.5);*/
  background: rgba(0, 0, 0, 0.5); /*#000000f0);*/
  z-index: 1000;
}

.mymodal-content {
  width: 90%;
  max-height: 80%;
  overflow-x: hidden;
  position: absolute;
  z-index: 10005;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 0.5rem;
  box-shadow: 0 0 1.5rem var(--content-modal-shadow-color);
  background: var(--content-modal-bg-color);
  /*scrollbar-track-color: var(--content-modal-bg-color);*/
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: thin; /* Firefox */
}

.mymodal-wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  /*background: rgba($color: #000000, $alpha: 0.5);*/
  /*background: rgba(0,0,0,0.5);!*#000000f0);*!*/
  z-index: 10000;
}

.mymodal-close {
  /*position: absolute;*/
  /*text-align: center;*/
  width: 1.5em;
  float: right;
  color: var(--text-color);
  background-color: var(--content-modal-close-color);
}
.mymodal-close:hover {
  /*position: absolute;*/
  /*text-align: center;*/
  /*width: 1.5em;*/
  /*float: right;*/
  background-color: var(--content-modal-close-hover-color);
}

@media (min-width: 576px) {
  .mymodal-route {
    width: 100%;
    height: 100%;
  }

  .mymodal-content {
    width: 50%;
    max-height: 80%;
  }
}
</style>